import React, { useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
const Lbox = ({ limage, lbox, setLbox, setLimage }) => {
  function closeLb() {
    //console.log('close');
    gsap.to('#lightbox', { opacity: 0, duration: 0.5 });
    gsap.fromTo('.currimg', { scale: 1 }, { scale: 0, duration: 0.2 });
    // gsap.to('#lightbox', { visibility: 'hidden', duration: 2 });
    setTimeout(() => {
      setLbox(false);
      setLimage();
    }, 500);
  }

  function closeLbKey(e) {
    //console.log(e.keyCode);
  }

  useEffect(() => {
    if (lbox) {
      //console.log(lbox, 'effect');
      gsap.to('#lightbox', { visibility: 'visible', duration: 0.5 });
      gsap.to('#lightbox', { opacity: 1, duration: 0.3 });
      gsap.fromTo('.currimg', { scale: 0 }, { scale: 1, duration: 0.2 });
    } else {
      //console.log(lbox, 'effect');
      gsap.to('#lightbox', { visibility: 'hidden', duration: 0 });
    }
  }, [lbox]);

  return (
    <div
      role="button"
      tabIndex="0"
      id="lightbox"
      onClick={closeLb}
      onKeyDown={(e) => closeLbKey(e)}
      className={`w-screen fixed overflow-auto pt-10 pb-10 h-screen  bg-gray-800  top-0 left-0 z-50 bg-opacity-80 ${
        lbox ? '' : ''
      }`}
    >
      <div className="  relative m-auto h-full">
        {limage && (
          <GatsbyImage
            className="currimg"
            style={{ height: '100%', width: '100%' }}
            image={limage.gatsbyImageData}
            alt="portfolio"
            objectFit="contain"
          />
        )}
      </div>
    </div>
  );
};

export default Lbox;
