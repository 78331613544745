import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
// import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import gsap from 'gsap';
import Cursor from '../components/Cursor';
import SeoBasic from '../components/SeoBasic';
import Colophon from '../components/Colophon';
import Header from '../components/Header';
import Lbox from '../components/Lbox';
import Scroll from '../components/Scroll';
import '../styles/portfoto.css';

const Pportfolio = ({ data, transitionStatus }) => {
  let picRef = useRef([]);
  useEffect(() => {
    if (transitionStatus) {
      //   console.log('animate', transitionStatus);
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.ptitle', {
        delay: 0.1,
        duration: 0.8,
        x: '-250',
        opacity: 0,
        ease: 'power4.inOut',
      });
      gsap.to('.pcate', {
        delay: 0.1,
        duration: 0.8,
        x: '250',
        opacity: 0,
        ease: 'power4.inOut',
      });
      gsap.to('#imagecontainer', {
        delay: 0.1,
        duration: 0.8,
        y: '10',
        opacity: 0,
        ease: 'power4.inOut',
      });
    }
    if (transitionStatus === 'entering') {
      gsap.from('.ptitle', {
        delay: 0.1,
        duration: 0.8,
        x: '-250',
        opacity: 0,
        ease: 'power4.inOut',
      });
      gsap.from('.pcate', {
        delay: 0.1,
        duration: 0.8,
        x: '250',
        opacity: 0,
        ease: 'power4.inOut',
      });
      gsap.from('#imagecontainer', {
        delay: 0.1,
        duration: 0.8,
        y: '10',
        opacity: 0,
        ease: 'power4.inOut',
      });
    }
  }, [transitionStatus]);

  const [cate, setCate] = useState('food');
  const [filtered, setFiltered] = useState('');
  const [lbox, setLbox] = useState(false);
  const [limage, setLimage] = useState(null);
  let images = [];

  function animate(myrex, x, y) {
    //console.log(myrex.selfRef.offsetWidth, myrex.selfRef.offsetHeight);
    let ox =
      myrex.selfRef.offsetLeft + Math.floor(myrex.selfRef.offsetWidth / 2);
    let oy =
      myrex.selfRef.offsetTop + Math.floor(myrex.selfRef.offsetHeight / 2);

    let noy = oy - document.documentElement.scrollTop;

    let mx = x - ox;
    let my = (y - noy) * -1;

    let nx = ((mx / myrex.selfRef.offsetHeight / 2) * 20).toFixed(2);

    let ny = ((my / myrex.selfRef.offsetWidth / 2) * 20).toFixed(2);
    //console.log(nx, ny);

    gsap.to(myrex.selfRef, {
      rotationY: nx * 2,
      rotationX: ny * 2,
      transformOrigin: 'center',
      transformPerspective: '300px',
      scale: 1.05,
      duration: 0.5,
    });
  }

  function reanimate(myrex, x, y) {
    //myrex.imageRef.style.trasform = 'scale(.2)';
    gsap.to(myrex.selfRef, {
      scale: 1,
      rotateX: 0,
      rotateY: 0,
      perspective: 0,
    });
  }
  useEffect(() => {
    //console.log(cate);
    const filteredItems = data.allFile.edges.filter(
      (item) => item.node.name.indexOf(cate) === 0
    );
    if (cate) gsap.to('#' + cate, { fontSize: '1.75rem', duration: 0.3 });
    setFiltered(filteredItems);
    // console.log(filteredItems);
  }, [cate, data]);

  function openLightbox(photo, event) {
    event.preventDefault();
    setLimage(photo);
    setLbox(true);
  }

  if (filtered)
    // filtered.map((ff) => {
    //   images.push(ff.node.childImageSharp);
    // });
    filtered.forEach(function (ff, index) {
      images.push(ff.node.childImageSharp);
    });

  const clickFilter = (filter, e) => {
    e.preventDefault();
    //console.log(filter, 'filtering');
    gsap.to('.pfolio', { fontSize: '1.25rem', duration: 0.3 });
    gsap.to('#' + filter, { fontSize: '1.75rem', duration: 0.3 });
    gsap.to('#imagecontainer', { opacity: 0, duration: 0.3 });
    setTimeout(() => {
      setCate();
    }, 300);
    gsap.fromTo(
      '#imagecontainer',
      { y: 0 },
      {
        y: -50,
        duration: 0.3,
        ease: 'power4.inOut',
      }
    );
    gsap.to('#imagecontainer', {
      opacity: 1,
      duration: 1,
      delay: 0.4,
    });
    gsap.fromTo(
      '#imagecontainer',
      { y: 50 },
      { y: 0, duration: 0.3, delay: 0.4, ease: 'power4.inOut' }
    );
    setTimeout(() => {
      setCate(filter);
    }, 300);
  };

  //   console.log(filtered);
  return (
    <main className="container mx-auto px-2 md:px-0">
      <Lbox
        limage={limage}
        lbox={lbox}
        setLbox={setLbox}
        setLimage={setLimage}
      />
      <SeoBasic slug="pportfolio" />
      <Cursor />
      <Header />
      <div className="w-full h-full flex justify-center items-center realizzazioni">
        <p className="list-none text-4xl md:text-6xl lg:text-8xl font-bold relative overflow-hidden uppercase transform ptitle">
          Portfolio Foto
        </p>
      </div>

      <div className="w-full h-full flex justify-center items-center pcate">
        <p className="list-none text-xl font-bold relative overflow-hidden uppercase transform">
          <a
            id="food"
            href="food"
            className="pfolio"
            onClick={(e) => clickFilter('food', e)}
          >
            Food
          </a>{' '}
          <a
            id="people"
            className="pfolio"
            href="people"
            onClick={(e) => clickFilter('people', e)}
          >
            People
          </a>{' '}
          <a
            id="still_life"
            className="pfolio"
            href="still_life"
            onClick={(e) => clickFilter('still_life', e)}
          >
            Still Life
          </a>{' '}
          <a
            id="music"
            className="pfolio"
            href="music"
            onClick={(e) => clickFilter('music', e)}
          >
            Music
          </a>
        </p>
      </div>
      <div className="gallery" id="imagecontainer">
        {images &&
          images.map((image, i) => {
            return (
              <GatsbyImage
                // ref={(el) => (picRef.current[i] = el)}
                // onMouseMove={({ clientX: x, clientY: y }) =>
                //   animate(picRef.current[i], x, y)
                // }
                // onMouseLeave={() => reanimate(picRef.current[i])}
                onClick={(e) => openLightbox(image, e)}
                key={i}
                image={image.gatsbyImageData}
                alt="portfolio"
                className="shadow-md"
                objectFit="cover"
              />
            );
          })}
      </div>
      <Scroll />
      <Colophon />
    </main>
  );
};
export const pquery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/p//" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;

export default Pportfolio;
